<template>
 <div :class="windowClass">
   <v-container>
     <v-toolbar flat>
       <div class="text-h4"> {{event.name}}</div> <v-chip color="info" class="ml-5">{{event.status}}</v-chip>
       <v-spacer/>
        <div class="text-h5"><v-icon left color="accent">mdi-calendar</v-icon>{{event.event_date | moment("MMM DD, YYYY")}}</div>
     </v-toolbar>
     <v-divider></v-divider>
    <v-row dense class="mt-10">
      <v-col cols="12">
     <v-toolbar flat>
       <v-spacer/>
       <v-btn large color="primary" @click=" state='new', form=true"><v-icon left >mdi-fencing</v-icon>Add Fight</v-btn>
     </v-toolbar>
      </v-col>
       <v-col cols="12">
        
          <v-data-table
            class="va-table"
            dense
            :headers="headers"
            hide-default-footer
            disable-pagination
            disable-sort
            :search="search"
            :items="fights"
            >
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-for="(item,index) in items" :key="index">
                    <td>
                       <!-- <v-icon v-if="item.status!='Finished'" left color="info" @click="fight=item, state='update', form=true" >mdi-pencil-box</v-icon>   -->
                      <strong>{{ item.fight_no }}</strong>
                    </td>
               
                      <td :class="STATUS_COLOR(item.status)"><strong>{{ item.status }}</strong></td>
                      <td :class="item.result.toLowerCase()" class="text-center pa-1" style="width: 170px; color: #fff;">
                        <template v-if="item.result">
                          <v-btn  text dark v-if="item.status != 'Closed'"  @click="fight=item, state='update', postfight=true" >{{item.result}}</v-btn>
                        </template>
                        <template v-else>
                            <v-btn color="primary" v-if="item.status != 'Finished'"  @click="fight=item, state='update', formform=true" >FIGHT CONTROLLER</v-btn>
                            <span v-else>----</span>
                        </template>
                       
                    </td>
                </tr>
                </tbody>
            </template>
            </v-data-table>
       </v-col>
    </v-row>
    </v-container>
      <va-fight-form :show="form" :state="state" :entry="fight" @DialogEvent="formEvent"/>
      <va-fight-controller :show="formform" :state="state" :entry="fight" @DialogEvent="formResEvent"/>
      <va-post-fight :show="postfight" :state="state" :entry="fight" @DialogEvent="formPostEvent"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        postfight: false,
        formform: false,
        state: 'new',
        fight:{},
        fights: [],
        event: {},
        search:'',
        headers: [
            { text: 'Fight No.', value:"event_date"},
            // { text: 'Meron', value:"meron", align:"left"},
            // { text: 'Wala', value:"wala", align:"left"},
            { text: 'Status', value:"status"},
            { text: 'Result', value:"result", align: 'right'},
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
        this.getItems()
        this.getEvent()
        this.GET_BREEDERS()
        this.GET_OWNERS()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.fight = data.fight
              this.Save()
          } else {
             this.getItems()
           
          }
      },
      formPostEvent(e) {
        if(e) {
          this.postfight = false
        }
        
      },
      formResEvent(){
        this.formform = false
        this.getItems()
        // setTimeout(()=>{
        //          this.getItems()
        //     }, 800)
      },
       getItems(){
          this.$http.post("event/fights", {id: parseInt(this.$route.params.id)}).then(response => {
            response.data.items !=null? this.fights = response.data.items:this.fights=[] 
           
        }).catch(e => {
          console.log(e)
        })
      },
       getEvent(){
          this.$http.post("event/get", {id: parseInt(this.$route.params.id)}).then(response => {
            response.data.status? this.event = response.data.item:this.event={} 
        }).catch(e => {
          console.log(e)
        })
      },
      Save: function() {
        let method = 'fight/add'
        this.state != 'new'?method='fight/update':method = 'fight/add'
        this.fight.event = this.event
        this.$http.post(method, this.fight).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>